<template>
  <div>
    <van-form @submit="onSubmit">
      <div class="body">
        <van-field
          v-model="form.name"
          name="name"
          label="姓名"
          readonly
          clickable
          placeholder="请输入您的姓名"
          left-icon="contact"
        >
          <template #left-icon>
            <img src="@/assets/img/patient/name.png" class="left-icon" alt="" />
          </template>
        </van-field>
        <van-field
          v-model="form.idType"
          name="idType"
          label="证件类型"
          left-icon="credit-pay"
          readonly
          clickable
        >
          <template #left-icon>
            <img
              src="@/assets/img/patient/idType.png"
              class="left-icon"
              alt=""
            />
          </template>
        </van-field>
        <van-field
          v-model="form.idNumber"
          name="idNumber"
          label="证件号码"
          left-icon="debit-pay"
          placeholder="请输入您的证件号"
          readonly
          clickable
        >
          <template #left-icon>
            <img
              src="@/assets/img/patient/idNumber.png"
              class="left-icon"
              alt=""
            />
          </template>
        </van-field>
        <van-cell-group>
          <van-field
            class="right-arrow"
            v-model="form.phone"
            type="tel"
            name="phone"
            label="手机号"
            left-icon="phone-o"
            placeholder="请输入您的手机号"
            error-message="如果手机号有误？请输入新的手机号"
            readonly
            clickable
          >
            <template #left-icon>
              <img
                src="@/assets/img/patient/phone.png"
                class="left-icon"
                alt=""
              />
            </template>
            <template #button>
              <!-- <van-icon size="18" name="arrow" @click="validPhone" /> -->
              <van-icon size="18" name="arrow" />
            </template>
          </van-field>
          <van-field
            class="right-arrow"
            v-model="form.patId"
            name="patId"
            type="number"
            label="就诊卡"
            left-icon="credit-pay"
            placeholder="请输入您的就诊卡号"
            readonly
            clickable
          >
            <template #left-icon>
              <img
                src="@/assets/img/patient/patientId.png"
                class="left-icon"
                alt=""
              />
            </template>
            <template #button>
              <van-icon size="18" name="arrow" />
            </template>
          </van-field>
        </van-cell-group>
      </div>
      <!--验证手机号-->
      <van-dialog v-model="validShow" title="验证手机号" show-cancel-button>
        <div class="dialog-phone">155****0305</div>
        <div class="dialog-input">
          <input
            type="number"
            class="captcha"
            name="captcha"
            placeholder="请输入验证码"
          />
        </div>
        <div class="dialog-button">
          <van-button type="primary" @click="getCaptcha" v-show="show"
            >获取验证码</van-button
          >
          <van-button type="primary" disabled class="disabled" v-show="!show"
            >{{ second }}秒后重新获取
          </van-button>
        </div>
      </van-dialog>
      <van-button class="bindJzCard" block type="info">绑定就诊卡</van-button>
    </van-form>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      form: {},
      validShow: false,
      captcha: "",
      show: true,
      second: 10,
    };
  },
  created() {
    this.form = JSON.parse(sessionStorage.getItem("card_cardItem"));
    this.form.idType = this.$route.query.idType;
  },
  methods: {
    onSubmit() {
      let postData = {
        healthid: this.form.healthId,
        userid: sessionStorage.getItem("userid"),
      };
      this.$http
        .post("/api/card/bind", postData)
        .then((res) => {
          if (res.status == 200) {
            Toast({
              message: "绑定成功！",
              duration: 500,
              onClose: () => {
                this.$router.push({
                  path: "/cardInformation",
                });
              },
            });
          } else {
            Toast.fail({ message: "绑定失败！" });
          }
        })
        .catch((err) => {
          Toast.fail({ message: err.response.data });
          console.log(err);
        });
    },
    // 验证手机号
    validPhone() {
      this.validShow = true;
    },
    // 获取验证码
    getCaptcha() {
      let _this = this; // 改变this指向
      this.show = false;
      let timer;
      clearInterval(timer);
      timer = setInterval(function () {
        _this.second--;
        if (_this.second == 0) {
          _this.show = true;
          clearInterval(timer);
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.body {
  background-color: #fff;
  margin: 0.3rem;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
  border-radius: 10px;
  overflow: hidden;
  padding: 0.2rem 0;
}

.bindJzCard {
  width: 9rem;
  border-radius: 5px;
  border: none;
  background-image: linear-gradient(to right, #59fdc5, #00d48b);
  font-size: 0.4rem;
  position: absolute;
  bottom: 25%;
  left: 50%;
  transform: translateX(-50%);
}

/deep/ .van-field__error-message {
  color: #8c8d8e;
}

/deep/ .van-dialog__header {
  background-color: #0dda93;
  color: #fff;
  padding: 15px;
}

.dialog-phone {
  margin: 15px auto;
}

.captcha {
  padding: 5px;
  border: 1px solid #dedfe0;
}

.dialog-button {
  margin: 15px auto;
}

.dialog-button .van-button {
  background-color: #0dda93;
  border: none;
  width: 5rem;
}

/deep/ .van-dialog__confirm,
.van-dialog__confirm:active {
  color: #63a900;
}

.disabled {
  background-color: #cbcccd !important;
  color: #ffffff;
}
.left-icon {
  width: 0.4rem;
  height: 0.4rem;
  position: relative;
  top: 2px;
}
</style>
